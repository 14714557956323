<template>
  <div class="list" ref="d">
    <div class="list-content" ref="c">
      <van-checkbox-group v-model="result" icon-size="16px" ref="checkboxGroup">
        <div
          class="contentcart"
          v-for="(row, index) in formConfig.data"
          :key="index"
        >
          <div class="contentcart-title">
            <span class="left">
              <van-checkbox
                :name="row"
                @click="checkboxfun"
                class="check-box"
                v-if="falg != 1 && (row.appStatus == 1 || !row.appStatus)"
              ></van-checkbox>
              <p v-if="!isId">{{ row.supplierName }}</p>
            </span>
            <div class="right" v-if="row.appStatus == 1">
              <div class="righttext"></div>
              等待审批
            </div>
            <div class="rightthrough" v-else-if="row.appStatus == 2">
              <div class="righttext"></div>
              审批通过
            </div>
            <div class="rightnothrough" v-else-if="row.appStatus == 3">
              <div class="righttext"></div>
              审批不通过
            </div>
            <div class="right" v-else>
              <div class="righttext"></div>
              等待确认
            </div>
          </div>
          <div class="cart-content" @click="rowClick(formConfig.url, row, formConfig.type)">
            <div class="cart-introduce">
              <div
                class="introduce-box"
                v-for="(item, i) in formConfig.columns"
                :key="i"
              >
                <span class="introduce-val specific">
                  <span class="introduce-way" v-if="item.custom">
                    <!-- <span v-if="item.label == '文件编号'">{{row.checktype == 1 ? '资格预审文件编号' : '招标文件编号'}}：</span> -->
                    <span v-if="item.label == '截止时间'">{{row.checktype == 1 ? '资审文件售卖截止时间' : '招标文件售卖截止时间'}}：</span>
                    <span v-else-if="item.label == '开标时间'">{{row.checktype == 1 ? '资格预审时间' : '开标时间'}}：</span>
                    <span v-else>{{ item.label }}：</span>
                  </span>
                  <span class="introduce-way" v-else>
                    {{ item.label }}：
                  </span>

                  <span v-if="item.custom">
                    <span v-if="item.label == '截止时间'">{{row.checktype == 1 ? row['zsfileend'] : row['zbfileend']}}</span>
                    <span v-else-if="item.label == '开标时间'">{{row.checktype == 1 ? row['preopentime'] : row['openTime']}}</span>
                    <span v-else>{{ row[item.prop] }}</span>
                  </span>

                  <span v-else>
                    {{ row[item.prop] }}
                  </span>
                  
                </span>
              </div>
              <div
                class="introduce-box"
                v-if="row.contractTypeName == '框架协议' && signing == 1"
              >
                <span class="introduce-val specific"
                  ><span class="introduce-way">签约方式:</span
                  >{{ row.directionName }}</span
                >
              </div>
            </div>
            <div class="cart-people" v-if="hasUser">
              <span><van-icon name="contact" size="30" /></span>
              <span>{{ row.createUser }}</span>
            </div>
          </div>
        </div>
      </van-checkbox-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "LayList",
  props: {
    formConfig: {
      require: true,
      type: Object,
      default: () => ({}),
      data: {
        required: true,
        type: Array,
        default: () => [],
      },
      columns: {
        required: true,
        type: Array,
        default: () => [],
      },
      total: {
        type: Number,
        default: 0,
      },
      url: {
        type: String,
        default: "",
      },
    },
    signing: {
      type: Number,
    },
    hasUser: {
      type: Boolean,
      default: true,
    },
    falg: {
      type: Number,
    },
    isId: {
      type: Number,
      default: 0,
    },
    classificationType: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      checked: false,
      result: [],
      // key: value
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() { },
  methods: {
    rowClick(url, row, type) {
      let query = {
        ...row,
        id: row.id,
        appStatus: row.appStatus,
        biddingPackageId: row.biddingPackageId,
        type: row.type,
        entityId: row.entityId,
        classificationType: this.classificationType,
        bidfilecode: row.bidfilecode,
        supeplanId: row.supeplanId,
        changeId: row.changeid || 0
      };
      if (type == 'path') {
        this.$router.push({ path: url, query });
      } else {
        this.$router.push({ name: url, query });
      }
    },
    checkboxfun() {
      if (this.result.length == this.formConfig.data.length) {
        this.checked = true;
        this.$emit("checked", this.checked);
        this.$emit("result", this.result);
      } else {
        this.checked = false;
        this.$emit("checked", this.checked);
        this.$emit("result", this.result);
      }
    },
  },
};
</script>
<style lang="less">
.list {
  .list-content {
    .contentcart {
      margin: 0.3rem;
      font-size: 16px;
      overflow: hidden;
      // min-height: 120px;
      background: #ffffff;
      border-radius: 10px;
      padding: 0.3rem;
      margin-bottom: 10px;
      .contentcart-title {
        min-height: 30px;
        padding: 0 6px;
        background: #eff6ff;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          height: 100%;
          color: #2d8aff;
          // width: 45px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            width: 200px;
          }
          .check-box {
            margin-right: 5px;
          }
        }
        .right {
          color: #333333;
          display: flex;
          font-size: 12px;
          align-items: center;
          .righttext {
            height: 10px;
            width: 10px;
            border-radius: 5px;
            background: #2d8aff;
            margin-right: 3px;
          }
        }
        .rightthrough {
          color: #333333;
          display: flex;
          font-size: 12px;
          align-items: center;
          .righttext {
            height: 10px;
            width: 10px;
            border-radius: 5px;
            background: #51c9e4;
            margin-right: 3px;
          }
        }
        .rightnothrough {
          color: #333333;
          display: flex;
          font-size: 12px;
          align-items: center;
          .righttext {
            height: 10px;
            width: 10px;
            border-radius: 5px;
            background: red;
            margin-right: 3px;
          }
        }
      }
      .cart-content {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #333;
        .cart-introduce {
          // width: 250px;
          //  background: brown;
          display: flex;
          flex-direction: column;
          .introduce-box {
            padding: 0.1rem 0rem;
            margin: 0 4px;
            .introduce-way {
              font-size: 12px;
              color: #8f8f8f;
              line-height: 20px;
            }
            .introduce-val {
              margin-left: 3px;
              font-size: 12px;
              color: #333333;
              line-height: 20px;
            }
          }
        }
        .cart-people {
          // width: 50px;
          display: flex;
          margin: 0 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .footerindex {
    border-top: 1px solid #2d8aff;
    height: 1.35rem;
    background: #f3f5fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    // padding: 0.3rem;
    position: fixed;
    width: 100%;
    bottom: 0px;
    .van-button--small {
      width: 1.6rem;
      height: 0.72rem;
      border-radius: 0.08rem;
    }
  }
}
.specific {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
