<template>
  <div class="box">
    <lay-title @return="returns" :cur="0" />

    <van-calendar
      v-if="showCalendar"
      v-model="showCalendar"
      type="range"
      @confirm="onSelect"
      color="#1989fa"
      :min-date="minDate"
      :max-date="maxDate"
      :poppable="false"
      :allow-same-day="true"
    >
    </van-calendar>

    <!-- NOTE 排序与筛选 日历出来的时候 应该隐藏 v-if="!showCalendar" -->
    <lay-tool @active="expandTools" v-if="!showCalendar" :curTab="curTab" />

    <div v-if="content" style="height: auto">
      <van-empty
        description="没有需要审批的单据"
        v-if="content && formConfig.data.length === 0 && !firstLoading"
      />

      <div
        style="
          height: calc(100vh - 1.22667rem - 1.17333rem);
          display: flex;
          align-items: center;
          justify-content: center;
        "
        v-if="firstLoading"
      >
        <van-loading vertical>加载中</van-loading>
      </div>

      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="刷新成功"
        v-if="content && formConfig.data.length != 0"
        style="margin-bottom: 1.35rem"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <!-- <list
            :formConfig="formConfig"
            ref="far"
            @checked="checkedfun"
            @result="resultfun"
          /> -->
          <!-- :hasUser="!!opt.hasUser" -->
          <lay-list
            :formConfig="formConfig"
            ref="far"
            @checked="checkedfun"
            @result="resultfun"
          />
        </van-list>
      </van-pull-refresh>
      <div class="footerindex" v-if="!showCalendar && statusType === 1">
        <div>
          <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
        </div>
        <van-button type="info" size="small" @click="through">通过</van-button>
      </div>
    </div>

    <div
      style="
        background-color: #ffffff;
        height: calc(100vh - 1.22667rem - 1.17333rem);
      "
      v-if="expandToolbar"
    >
      <lay-sequence-block @filter="sequenceTool" v-if="this.curTab === 0" />
      <lay-filter-block
        v-if="this.curTab === 1"
        @open="openCalendar"
        @filter="filterTool"
        :filterDate="filterDate"
        :curStatus="statusType"
      />

      <div class="footer">
        <div class="left" @click="onReset">重置</div>
        <div class="right" @click="onConfirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import LayTitle from "./components/LayTitle";
import LayList from "./components/LayList";
import LayTool from "./components/LayTool";
import LayFilterBlock from "./components/LayFilterBlock";
import LaySequenceBlock from "./components/LaySequenceBlock";

import common from "@utils/common";

export default {
  data() {
    return {
      // NOTE dom的显示隐藏
      showToolbar: true, // 是否显示工具栏
      expandToolbar: false, // 是否展开工具栏
      showCalendar: false, // 是否显示日历
      content: true, // 是否显示内容区域
      firstLoading: false, // 加载中圈圈
      count: 1, // 刷新次数

      // NOTE 公共参数
      loading: false, // 下拉刷新 是否正在加载
      finished: false, // 所有数据加载状态，完成为true
      isLoading: false, // 是否正在刷新
      pageIndex: 1, // 分页页数
      pageSize: 10, // 分页数据数量
      formConfig: {}, // 列表参数
      opt: {}, // 当前页面参数，从原型链中取得

      checked: false,
      result: [],
      minDate: "",
      maxDate: new Date(),

      // NOTE 筛选工具栏相关
      sort: "desc", // 排序方式
      sortType: 1, // 排序属性
      statusType: 1, // 审批状态
      compId: "", // 编制单位id
      filterDate: {
        submit: { start: "", end: "", text: "" },
        examine: { start: "", end: "", text: "" },
      }, // 提报日期与审批日期
      curTab: 0, // 工具栏当前选中
      filterDateType: "", // 工具栏的日期类型
    };
  },
  mounted() {},
  components: {
    LayTitle,
    LayList,
    LayTool,
    LayFilterBlock,
    LaySequenceBlock,
  },
  async created() {
    this.init();
    this.getLists();
    this.minDate = new Date(this.createBeginDate);
  },

  computed: {
    createBeginDate() {
      let date = new Date();
      date.setMonth(date.getMonth() - 3);
      date.toLocaleDateString();

      let month = date.getMonth() + 1;
      let day = date.getDate();

      let mm = month < 10 ? "0" + month : month;
      let dd = day < 10 ? "0" + day : day;

      return `${date.getFullYear()}-${mm}-${dd}`;
    },
  },

  // watch: {
  //   $route: {
  //     handler(newVal, oldVal) {
  //       console.log("val", oldVal);
  //       if (String(newVal.query.type) === "1") {
  //         this.showToolbar = true; // 是否显示工具栏
  //         this.expandToolbar = false; // 是否展开工具栏
  //         this.showCalendar = false; // 是否显示日历
  //         this.content = true; // 是否显示内容区域
  //         this.firstLoading = false; // 加载中圈圈
  //         this.count = 1; // 刷新次数
  //         // NOTE 公共参数
  //         this.loading = false; // 下拉刷新 是否正在加载
  //         this.finished = true; // 所有数据加载状态，完成为true
  //         this.isLoading = false; // 是否正在刷新
  //         this.init();
  //         this.getLists();
  //       }
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    /** 页面初始化 */
    init() {
      if (this.count === 1 && this.pageIndex === 1) {
        console.log("首次加载", this.firstLoading);
        this.firstLoading = true;
      }

      // 路由表中定义的当前页面路由
      let route = this.$route.params.pages;
      let opt = this.$layouts[route];
      if (!opt) {
        this.$router.replace({ name: "notFound" });
        return;
      }

      this.opt = opt;
      this.formConfig = opt.formConfig;
    },

    /** 获取页面数据 */
    async getLists() {
      console.log("页码", this.pageIndex);

      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        statusType: this.statusType,
        sort: this.sort,
        sortType: this.sortType,
        submitBeginTime: this.filterDate.submit.start,
        submitEndTime: this.filterDate.submit.end,
        approveBeginTime: this.filterDate.examine.start,
        approveEndTime: this.filterDate.examine.end,
        compId: this.compId,
        appSearchType: this.opt.searchType,
        // type: 0 // 物流审核为1  物流确认为0
        // isChange: 0, // 物流变更为1  物流为0
      };

      // 物流方案确认列表查询isApproveType==0，物流方案审核列表查询isApproveType==1

      if (this.opt.logisticsAudit) {
        if (this.opt.logisticsAudit == 1) {
          params["type"] = 1;
        } else {
          params["type"] = 0;
        }
      }

      if (this.opt.logisticsChange) {
        if (this.opt.logisticsChange == 1) {
          params["isChange"] = 1;
        } else {
          params["isChange"] = 0;
        }
      }

      if (this.opt.schemeChange) {
        params["isChange"] = this.opt.schemeChange;
      }

      if (this.opt.isApproveType) {
        params["isApproveType"] = this.opt.isApproveType;
      }

      // 请求数据
      let data = await this.$api.ec[`${this.opt.listApi}`](params);
      if (data.length === 0 || data.data) {
        if (this.count === 1 && this.pageIndex === 1) {
          this.firstLoading = false;
        }
      }

      if (data.data) {
        data.data.map(this.opt.fillingCard); // 处理卡片数据

        this.isLoading = false;
        let total = data.total;

        if (total < this.pageSize) {
          this.formConfig.data = data.data;
          this.loading = false;
          this.finished = true;
        } else {
          if (this.pageIndex == 1) {
            this.formConfig.data = data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.formConfig.data = [...this.formConfig.data, ...data.data];

            if (this.formConfig.data.length >= total) {
              this.finished = true;
            }
          }
        }
      } else {
        this.formConfig.data = [];
        this.loading = false;
        this.finished = true;
      }
    },

    /** 返回按钮 */
    returns() {
      if (this.showCalendar) {
        this.showCalendar = false;
        this.showToolbar = true;
        this.expandToolbar = true;
      } else if (this.expandToolbar) {
        this.expandToolbar = false;
        this.content = true;
      } else {
        window.location.replace(process.env.VUE_APP_INDEX);
      }
    },

    /** 日历组件确定按钮点击事件 */
    onSelect(date) {
      let tempStatus = this.statusType;
      let start = common.formatDate(date[0]);
      let end = common.formatDate(date[1]);
      let section = `${start} - ${end}`;

      let type = this.filterDateType;

      this.filterDate[type]["start"] = start;
      this.filterDate[type]["end"] = end;
      this.filterDate[type]["text"] = section;

      this.filterDateType = "";
      this.showCalendar = false;
      this.showToolbar = true;
      this.expandToolbar = true;
      this.statusType = tempStatus;
    },

    /** 打开日历组件 */
    openCalendar(val) {
      this.filterDateType = val;
      this.showCalendar = true;
      this.expandToolbar = false;
      this.showToolbar = false;
      this.content = false;
    },

    /** 下拉刷新 */
    onRefresh() {
      this.count++;
      this.pageIndex = 1;
      this.finished = false;
      this.loading = true;
      this.getLists();
    },

    /** 上拉加载 */
    onLoad() {
      this.pageIndex++;
      this.getLists();
    },

    /** 工具栏确认按钮 */
    onConfirm() {
      this.expandToolbar = false;
      this.content = true;
      this.showToolbar = true;
      this.formConfig.data = []; // 数据置空
      this.pageIndex = 1; // 恢复页码
      this.firstLoading = true
      this.getLists();
      this.filterDate = {
        submit: { start: "", end: "", text: "" },
        examine: { start: "", end: "", text: "" },
      }; // 时间置空
    },

    /** 工具栏重置按钮 */
    onReset() {
      this.expandToolbar = false;
      this.content = true;
      this.showToolbar = true;
      this.pageIndex = 1;
      this.pageSize = 10;
      this.statusType = 1;
      this.sort = "desc";
      this.sortType = 1;
      this.compId = "";
      this.filterDate = {
        submit: { start: "", end: "", text: "" },
        examine: { start: "", end: "", text: "" },
      };
      this.formConfig.data = [];
      this.firstLoading = true
      this.getLists();
    },

    /** 列表审批 */
    async auditProjectList() {
      let choose = "";
      let changeIds = "";
      let result = this.$refs.far.result;

      let route = this.$route.params.pages;

      let specialPages = ["scheme", "schemeChange", "schemeConfirm"];

      let includesPage = specialPages.find((item) => item === route);

      result.map((item) => {
        if (includesPage) {
          choose += `${item.supeplanId},`;
        } else {
          choose += `${item.id},`;
        }
      });
      let val = choose.slice(0, choose.length - 1);

      let params = {};
      let api = this.opt.listApproveApi;
      let key = this.opt.listApproveParam;
      params[key] = val;

      if (route === "schemeChange") {
        result.map((item) => (changeIds += `${item.changeid}`));
        params["changeIds"] = changeIds.slice(0, choose.length - 1);
      }

      let data = await this.$api.ec[api](params);

      if (data) {
        this.formConfig.data = [];
        this.pageIndex = 1;
        Toast.success("提交成功");
        this.getLists();
      }
    },

    /** 点击展开工具栏 */
    expandTools(val) {
      this.expandToolbar = true;
      this.content = false;
      this.curTab = val;
    },

    /** 排序回传 */
    sequenceTool(val) {
      this.sort = val.sort;
      this.sortType = val.sortType;
    },

    /** 筛选回传 */
    filterTool(val) {
      this.statusType = val.statusType;
      this.compId = val.compId;
    },

    /** 选择相关 */
    through() {
      if (this.$refs.far.result.length > 0) {
        Dialog.confirm({ title: "审批", message: "是否同意" })
          .then(() => {
            this.auditProjectList();
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Toast.fail("请至少选择一个审核内容");
      }
    },
    checkAll() {
      this.$refs.far.$refs.checkboxGroup.toggleAll(this.checked);
    },
    checkedfun(data) {
      this.checked = data;
    },
    resultfun(data) {
      this.result = data;
    },
  },
};
</script>

<style lang="less">
.issfxed {
  border: 1px solid #9cdeec !important;
}

.butss {
  // background: chocolate;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  .van-button--small {
    padding: 0 1.7rem;
  }
}

.vhhg {
  background-color: #f3f5fa;
  min-height: 100%;
  font-size: 12px;
  .box {
    display: flex;
    flex-direction: column;
    .header {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
      .header-search {
        height: 44px;
        font-size: 18px;
        color: #f9f9fb;
        text-align: center;
        line-height: 44px;
        .van-nav-bar {
          background: linear-gradient(to right, #5ca7f0, #2f6edd);
          .van-nav-bar__content {
            .van-nav-bar__title {
              font-size: 0.4rem;
              font-family: medium;
              font-weight: 400;
            }
          }
        }
      }
      .choose {
        min-height: 1.17333rem;
        .choose-div {
          display: flex;
          justify-content: space-around;

          height: 1.17333rem;
          line-height: 0.53333rem;
          background: #fff;
          align-items: center;
          color: #8f8f8f;
          font-size: 12px;
          // .choosetext{
          //   display: flex;
          //   align-items: center;
          //   justify-content:center
          // }
        }
      }
    }
    .contentbox {
      top: 46px !important;
    }
    .content {
      background-color: #f3f5fa;
      position: relative;
      top: 92px;
      // margin-top:92px;
      width: 100%;
      min-height: 400px;
      padding-bottom: 1.35rem;
      .contentsorting {
        // min-height: 460px;
        background: #fff;
        .van-tabs {
          .van-tabs__wrap {
            border-bottom: 1px solid rgb(255, 248, 248);
          }
        }
        .sorting {
          padding: 0.3rem;
          .sortingtit {
            margin-top: 10px;
            margin-bottom: 10px;
            color: #333;
            font-weight: 500;
            // font-size: 12px;
          }
          .dissolution {
            min-height: 80px;
            .people {
              float: left;
              width: 60px;
              height: 80px;
              // background: deeppink;
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // flex-shrink:0;
              position: relative;
              .contacti {
                position: absolute;
                top: 10px;
                right: 0;
                color: red;
              }
            }
          }
          .sortingbut {
            // background: chocolate;
            padding-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .van-button--small {
              padding: 0 1.7rem;
            }
          }
          .reverse {
            font-size: 12px;
            color: #333;
            border: 1px solid rgb(245, 239, 239);
            line-height: 40px;
            padding: 0 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .examinationtitle {
        font-size: 14px;
        line-height: 40px;
        margin-left: 4px;
        color: #8f8f8f;
        font-weight: 500;
      }
    }
    .footerindex {
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #2d8aff;
      height: 1.35rem;
      background: #f3f5fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem;
      // padding: 0.3rem;
      .van-button--small {
        width: 1.6rem;
        height: 0.72rem;
        border-radius: 0.08rem;
      }
    }
    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #2d8aff;
      height: 1.35rem;
      background: #f3f5fa;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .left {
        line-height: 1.35rem;
        width: 50%;
        text-align: center;
        font-size: 14px;
        color: #2d8aff;
      }
      .right {
        font-size: 14px;
        line-height: 1.35rem;
        width: 50%;
        color: #fff;
        text-align: center;
        background: #2d8aff;
      }
      .van-button--normal {
        padding: 0.08rem 4rem;
      }
    }
  }
}
.issfxed {
  border: 1px solid #9cdeec !important;
  .van-button__content {
    .van-button__text {
      color: #9cdeec;
    }
  }
}
.supplies {
  margin-top: 10px;
  // min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;

  .supplies-title {
    font-weight: 500;
    line-height: 25px;
    font-size: 13px;
    color: #312f2f !important;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.disappear {
  .van-tabs__wrap {
    height: 0px !important;
  }
}
@font-face {
  font-family: "iconfont"; /* Project id 2792967 */
  src: url("//at.alicdn.com/t/font_2792967_7dejnr6v63s.woff2?t=1630915077358")
      format("woff2"),
    url("//at.alicdn.com/t/font_2792967_7dejnr6v63s.woff?t=1630915077358")
      format("woff"),
    url("//at.alicdn.com/t/font_2792967_7dejnr6v63s.ttf?t=1630915077358")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shanchu:before {
  content: "\e629";
}
</style>
