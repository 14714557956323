<template>
  <div style="padding: 0.3rem">
    <div
      style="
        margin-top: 10px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 500;
      "
    >
      审批状态
    </div>
    <div class="butss">
      <van-button
        size="small"
        @click="switchStatusType(1)"
        :class="{ issfxed: statusType == 1 }"
      >
        待审批
      </van-button>
      <van-button
        size="small"
        @click="switchStatusType(2)"
        :class="{ issfxed: statusType == 2 }"
        >已审批</van-button
      >
    </div>
    <div
      style="
        margin-top: 10px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 500;
      "
    >
      编制单位
    </div>
    <div
      style="
        color: #333;
        border: 1px solid rgb(245, 239, 239);
        line-height: 40px;
        padding: 0 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -1px;
      "
      @click="reverseOrder(0)"
    >
      全部
      <van-icon name="success" v-if="compId == ''" color="#2D8AFF" />
    </div>
    <div
      style="
        color: #333;
        border: 1px solid rgb(245, 239, 239);
        line-height: 40px;
        padding: 0 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -1px;
      "
      @click="reverseOrder(item)"
      v-for="(item, index) in unit"
      :key="index"
    >
      {{ item.compShortName }}
      <van-icon name="success" v-if="compId == item.compId" color="#2D8AFF" />
    </div>
    <div
      style="
        margin-top: 10px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 500;
      "
    >
      提报日期
    </div>
    <van-cell-group>
      <!-- v-model="submitDate" -->
      <van-field
        v-model="filterDate.submit.text"
        placeholder="请选择"
        @focus="openCalendar('submit')"
      />
    </van-cell-group>
    <div
      style="
        margin-top: 10px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 500;
      "
    >
      审批日期
    </div>
    <van-cell-group>
      <van-field
        v-model="filterDate.examine.text"
        placeholder="请选择"
        @focus="openCalendar('examine')"
      />
    </van-cell-group>
  </div>
</template>

<script>
// 需要把审批状态、编制单位、提报日期、审批日期抛出去
export default {
  name: "LayFilterBlock",
  data() {
    return {
      statusType: this.curStatus || 1, // 审批状态
      compId: "", // 编制单位id
      unit: [], // 筛选编制单位

      submitDate: "", // 筛选卡片中提报日期 字符串
      examineDate: "", // 筛选卡片中审批日期 字符串
    };
  },
  components: {},
  props: {
    filterDate: {
      type: Object,
      default: () => ({}),
    },
    curStatus: {
      type: Number
    }
  },
  created() {
    this.toCompInfo()
  },
  mounted() {},
  methods: {
    // NOTE 获取 筛选选项卡 编制单位列表
    async toCompInfo() {
      let data = await this.$api.ec.toCompInfo();
      this.unit = data.data;
    },

    // NOTE 日历的点击方法
    openCalendar(val) {
      this.$emit('open', val)
    },

    // NOTE 切换 筛选选项卡 编制单位
    reverseOrder(val) {
      this.compId = val == 0 ? "" : val.compId;

      this.transferData()
    },

    switchStatusType(val) {
      this.statusType = val

      this.transferData()
    },

    // 传递按钮状态
    transferData() {
      let params = {
        statusType: this.statusType,
        compId: this.compId,
      }

      this.$emit('filter', params)
    }
  },
};
</script>

<style></style>
