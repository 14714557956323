<template>
  <div id="tabssss" style="margin-top: 1.22667rem; height: 1.17333rem">
    <van-tabs
      v-model="active"
      @click="expandTool()"
      style="border-bottom: 0.02667rem solid #fff8f8"
    >
      <van-tab>
        <template #title>排序 <van-icon name="ascending" /></template>
      </van-tab>
      <van-tab>
        <template #title>筛选 <van-icon name="filter-o" /></template>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "LayTool",
  data() {
    return {
      active: this.curTab || 0,
    };
  },
  components: {},
  props: {
    curTab: {
      type: Number
    }
  },
  created() {},
  mounted() {},
  methods: {
    expandTool() {
      // console.log('this.active', this.active)
      this.$emit('active', this.active)
    }
  },
};
</script>

<style></style>
