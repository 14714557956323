<template>
  <div style="padding: 0.3rem">
    <div v-for="(sortd, index) in sorts" :key="index">
      <div
        style="
          margin-top: 10px;
          margin-bottom: 10px;
          color: #333;
          font-weight: 500;
        "
      >
        {{ sortd.title }}
      </div>
      <div v-for="(item, index) in sortd.content" :key="index">
        <div
          style="
            color: #333;
            border: 1px solid rgb(245, 239, 239);
            line-height: 40px;
            padding: 0 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: -1px;
          "
          @click="sorting(sortd.mode, item.type)"
        >
          <span>{{ item.title }}</span>
          <van-icon
            name="success"
            color="#2d8aff"
            v-show="sort == `${item.type}` || sortType == `${item.type}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 要把排序方式、排序属性丢出去
export default {
  name: "LaySequenceBlock",
  data() {
    return {
      sort: "desc", // 排序方式
      sortType: 1, // 排序属性
      // 展示内容数组
      sorts: [
        {
          title: "排序方式",
          mode: "way",
          content: [
            { title: "倒序排序", type: "desc" },
            { title: "正序排序", type: "asc" },
          ],
        },
        {
          title: "排序属性",
          mode: "prop",
          content: [
            { title: "提报日期", type: 1 },
            { title: "提报人", type: 2 },
            { title: "编制单位", type: 3 },
          ],
        },
      ],
    };
  },
  components: {},
  props: {
  },
  created() {},
  mounted() {},
  methods: {
    sorting(mode, type) {
      if (mode == "way") {
        this.sort = type;
      } else {
        this.sortType = type;
      }

      this.$emit('filter', {sort: this.sort, sortType: this.sortType})
    },
  },
};
</script>

<style></style>
