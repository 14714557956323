<template>
  <div class="header">
    <div class="header-search">
      <van-nav-bar
        :title="title"
        left-arrow
        @click-left="returnFun"
        @click-right="searchFun"
      >
        <template #right v-if="right">
          <van-icon name="search" size="18" />
        </template>
      </van-nav-bar>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayTitle",
  data() {
    return {
      title: "",
    };
  },
  created() {
    let page = this.$route.params.pages;
    if (this.cur === 0) {
      this.title = this.$layouts[page].pageTitle;
    } else {
      this.title = this.bar;
    }
  },
  methods: {
    returnFun() {
      this.$emit("return");
    },
    searchFun() {
      let route = this.$route.params.pages;
      let opt = this.$layouts[route];
      this.$router.push({ path: opt.searchPage });
    },
  },
  props: {
    /** 是否隐层搜索按钮 */
    right: {
      type: Boolean,
      default: true,
    },
    /** 标题名称 */
    bar: {
      type: String,
      default: "",
    },
    /** 当前页面状态 */
    cur: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style></style>
